import React from "react";
const HeroSection = () => {
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact-section");
    if (contactSection) {
      contactSection.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  };
  return (
    <main id="hero-section">
      <div className="hero-content-wrapper">
        <div className="hero-info">
          <h1 className="hero-heading">
            <div className="title-wrapper">
              <span className="time">Customers</span>
              <span className="is">are</span>
            </div>
            <span className="everything">EVERYTHING</span>
          </h1>
          <p className="hero-paragraph">
            <strong>
              We generate {" "}
              <span className="highlight">
                {" "}
                targeted high-quality premium leads 
              </span>{" "}
              specialized in Forex & Crypto trading via our massive network of
              Affiliates, PPC, Programmatic and Media Buyers.
            </strong>
            <ul className="hero-bullet-points">
              <li>
                Looking to supercharge your business with motivated,
                <span className="highlight"> ready-to-convert leads? </span>We
                are your trusted partner.
              </li>
              <li>
                Our proven track record speaks for itself with over
                <span className="highlight"> 200+ satisfied clients</span>.
              </li>
              <li>
                Get access to targeted leads that understand the{" "}
                <span className="highlight">value</span> of Forex/Crypto trading
                opportunities.
              </li>
              <li>
                While our primary focus is delivering exceptional results in{" "}
                <span className="highlight">Latin America's</span> thriving
                market, we also successfully operate and serve clients across
                all regulated countries worldwide.
              </li>
            </ul>
          </p>
          <div className="hero-action-buttons">
            <button onClick={scrollToContact} className="btn primary-btn">
              BOOK A CALL NOW
            </button>
          </div>
        </div>
        <div className="hero-section-img-container">
          <div className="background-ele"></div>
          <img
            src="/header.png"
            data-aos="zoom-out"
            className="hero-img"
            alt="Hero"
          />
          <div className="hero-overlay-elements">
            <a
              href="https://www.instagram.com/learnfury/"
              className="hero-img-social-link"
              data-aos="zoom-out"
              data-aos-delay="450"
            >
              <img src="/instagram.png" alt="Instagram" />
            </a>
            <div
              className="subscribers"
              data-aos="zoom-out"
              data-aos-delay="300"
            >
              <h1>100+</h1>
              <p>Active affiliates</p>
            </div>
            <div
              className="hero-review"
              data-aos="zoom-out"
              data-aos-delay="600"
            >
              <i className="fa-solid fa-quote-left"></i>
              <p>
                Their cold leads offers have been instrumental to our success.
                The premium quality and steady flow of their leads drove our
                business to new heights.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default HeroSection;
